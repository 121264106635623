<template>
  <div v-loading.fullscreen="loading" element-loading-spinner="el-icon-loading" :element-loading-text=loadingText>
  <!-- header -->
  <div>
    <div class="flex justify-center mt-8">
      <div class="flex items-center space-x-8 max-w-4xl p-4 md:w-4/5 text-left">
        <img class=" h-24" src="../../src/assets/Logo_orange_text-mainlogo.jpg" alt="logo"/>
          <p class="muench text-2xl font-light pt-4">Feedback</p>
      </div>
    </div>
  <router-view />
  </div>
    <!-- main window -->
    <div class="flex justify-center " v-if="showError == false && !this.submittedSuccesfully">
      <div class="border max-w-4xl p-4 rounded shadow-md mb-4 w-7/8 md:w-4/5 text-left bg-white z-10 ">
        <p class="text-lg pl-3 mb-3" > We love feedback. It drives us to do better.</p>
        <hr class="border border-dotted mb-4" />
        <!-- Form -->
        <el-form :model="formFields" :rules="rules" ref="formFields" class="w-full mt-2 text-left space-y-2">
          <div class="flex justify-center">
            <!-- Name -->
            <div class="block w-full lg:mx-8 space-y-2">
              <p class="font-medium pl-2"> Name <span class="text-red-500">*</span> </p>
              <div class="flex w-full lg:flex-row flex-col lg:space-x-2">
                <el-form-item prop="nameFirst" class="lg:w-1/2">
                  <el-input placeholder="First" v-model="formFields.nameFirst" ></el-input>
                </el-form-item>
                <el-form-item prop="nameLast" class="block lg:w-1/2">
                  <el-input placeholder="Last" v-model="formFields.nameLast" ></el-input>
                </el-form-item>
              </div>
            <!-- Email -->
            <div>
              <p class="font-medium pl-2"> Email <span class="text-red-500">*</span> </p>
              <el-form-item prop="email">
               <el-input placeholder="Email" v-model="formFields.email" ></el-input>
                </el-form-item>
            </div>
            <!-- Workshop Attended -->
            <div class="border-b-1">
              <div class="flex">
                <p class="font-medium pl-2"> Which workshop did you attend? <span class="text-red-500">*</span> </p>
                <el-checkbox v-model="formFields.otherWorkshop" :label="true" class="pl-4">Other</el-checkbox>
              </div>
              <el-form-item prop="workshopAttended">
               <!-- <el-autocomplete class="inline-input mb-2 w-full" v-model="formFields.workshopAttended" placeholder="Workshop" :fetch-suggestions="queryWorkshops"></el-autocomplete> -->
               <el-select v-if="!this.formFields.otherWorkshop" class="w-full" v-model="formFields.workshopAttended" placeholder="Select Workshop">
                 <el-option v-for="item in workshops" :key="item.value" :label="item.label" :value="item"></el-option>
              </el-select>
              <el-input v-else placeholder="Please Specify" v-model="formFields.workshopAttended" ></el-input>
                </el-form-item>
            </div>
            <!-- Expectations Rating-->
            <div>
              <p class="font-medium pl-2 mt-8"> Did the workshop meet your expectations? <span class="text-red-500">*</span> </p>
                <!-- star rating -->
                <el-rate class="font-medium pl-2 mt-3" :class="{ invalid: isInvalid.expectationsRating }" v-model="formFields.expectationsRating"></el-rate>
                <span class="text-white p-0 m-0 text-xs" :class="{ invalidText: isInvalid.expectationsRating, validText: !isInvalid.expectationsRating}">Please select a rating</span>
            </div>
            <!-- Expectations Comments-->
            <div class="border-b-1">
              <p class="font-medium pl-2"> Comments</p>
              <el-form-item prop="expectationsComments">
               <el-input placeholder="Comments" type="textarea" rows="3" class="mb-2 w-full" v-model="formFields.expectationsComments" ></el-input>
                </el-form-item>
            </div>
            <!-- Photo Opp Rating-->
            <div>
              <p class="font-medium pl-2 mt-8"> Photo Opportunities we arranged <span class="text-red-500">*</span> </p>
                <!-- star rating -->
                <el-rate class="font-medium pl-2 mt-3" :class="{ invalid: isInvalid.photoOppRating }" v-model="formFields.photoOppRating"></el-rate>
                <span class="text-white p-0 m-0 text-xs" :class="{ invalidText: isInvalid.photoOppRating, validText: !isInvalid.photoOppRating}">Please select a rating</span>
            </div>
            <!-- Photo Opp Comments-->
            <div class="border-b-1">
              <p class="font-medium pl-2"> Comments</p>
              <el-form-item prop="photoOppComments">
               <el-input placeholder="Comments" type="textarea" rows="3" class="mb-2 w-full" v-model="formFields.photoOppComments" ></el-input>
                </el-form-item>
            </div>
            <!-- Instruction Rating-->
            <div>
              <p class="font-medium pl-2 mt-8"> Instruction <span class="text-red-500">*</span> </p>
                <!-- star rating -->
                <el-rate class="font-medium pl-2 mt-3" :class="{ invalid: isInvalid.instructionRating }" v-model="formFields.instructionRating"></el-rate>
                <span class="text-white p-0 m-0 text-xs" :class="{ invalidText: isInvalid.instructionRating, validText: !isInvalid.instructionRating}">Please select a rating</span>
            </div>
            <!-- Instruction Comments-->
            <div class="border-b-1">
              <p class="font-medium pl-2"> Comments</p>
              <el-form-item prop="instructionComments">
               <el-input placeholder="Comments" type="textarea" rows="3" class="mb-2 w-full" v-model="formFields.instructionComments" ></el-input>
                </el-form-item>
            </div>
            <!-- Critique Rating-->
            <div>
              <p class="font-medium pl-2 mt-8"> Critiques and classroom instruction <span class="text-red-500">*</span> </p>
                <!-- star rating -->
                <el-rate class="font-medium pl-2 mt-3" :class="{ invalid: isInvalid.critiqueRating }" v-model="formFields.critiqueRating"></el-rate>
                <span class="text-white p-0 m-0 text-xs" :class="{ invalidText: isInvalid.critiqueRating, validText: !isInvalid.critiqueRating}">Please select a rating</span>
            </div>
            <!-- Critique Comments-->
            <div class="border-b-1">
              <p class="font-medium pl-2"> Comments</p>
              <el-form-item prop="critiqueComments">
               <el-input placeholder="Comments" type="textarea" rows="3" class="mb-2 w-full" v-model="formFields.critiqueComments" ></el-input>
                </el-form-item>
            </div>
            <!-- Accomodation Rating-->
            <div>
              <p class="font-medium pl-2 mt-8"> Accommodation, Food and Comfort <span class="text-red-500">*</span> </p>
                <!-- star rating -->
                <el-rate class="font-medium pl-2 mt-3" :class="{ invalid: isInvalid.accomodationRating }" v-model="formFields.accomodationRating"></el-rate>
                <span class="text-white p-0 m-0 text-xs" :class="{ invalidText: isInvalid.accomodationRating, validText: !isInvalid.accomodationRating}">Please select a rating</span>
            </div>
            <!-- Accomodation Comments-->
            <div class="border-b-1">
              <p class="font-medium pl-2"> Comments</p>
              <el-form-item prop="accomodationComments">
               <el-input placeholder="Comments" type="textarea" rows="3" class="mb-2 w-full" v-model="formFields.accomodationComments" ></el-input>
                </el-form-item>
            </div>
            <!-- Attend New Workshop Rating-->
            <div>
              <p class="font-medium pl-2 mt-8"> Would you go on another workshop with us? <span class="text-red-500">*</span> </p>
                <!-- star rating -->
                <el-rate class="font-medium pl-2 mt-3" :class="{ invalid: isInvalid.attendNewWorkshopRating }" v-model="formFields.attendNewWorkshopRating"></el-rate>
                <span class="text-white p-0 m-0 text-xs" :class="{ invalidText: isInvalid.attendNewWorkshopRating, validText: !isInvalid.attendNewWorkshopRating}">Please select a rating</span>
            </div>
            <!-- Attend New Workshop Comments-->
            <div class="border-b-1">
              <p class="font-medium pl-2"> Comments</p>
              <el-form-item prop="attendNewWorkshopComments">
               <el-input placeholder="Comments" type="textarea" rows="3" class="mb-2 w-full" v-model="formFields.attendNewWorkshopComments" ></el-input>
                </el-form-item>
            </div>
            <!-- Worth It Rating-->
            <div>
              <p class="font-medium pl-2 mt-8"> Was this workshop worth what you paid for it? <span class="text-red-500">*</span> </p>
                <!-- star rating -->
                <el-rate class="font-medium pl-2 mt-3" :class="{ invalid: isInvalid.worthItRating }" v-model="formFields.worthItRating"></el-rate>
                <span class="text-white p-0 m-0 text-xs" :class="{ invalidText: isInvalid.worthItRating, validText: !isInvalid.worthItRating}">Please select a rating</span>
            </div>
            <!-- Worth It Comments-->
            <div class="border-b-1">
              <p class="font-medium pl-2"> Comments</p>
              <el-form-item prop="worthItComments">
               <el-input placeholder="Comments" type="textarea" rows="3" class="mb-2 w-full" v-model="formFields.worthItComments" ></el-input>
                </el-form-item>
            </div>
            <!-- Would Recommend Rating-->
            <div>
              <p class="font-medium pl-2 mt-8">Would you recommend us to a friend? <span class="text-red-500">*</span> </p>
                <!-- star rating -->
                <el-rate class="font-medium pl-2 mt-3" :class="{ invalid: isInvalid.wouldRecommendRating }" v-model="formFields.wouldRecommendRating"></el-rate>
                <span class="text-white p-0 m-0 text-xs" :class="{ invalidText: isInvalid.wouldRecommendRating, validText: !isInvalid.wouldRecommendRating}">Please select a rating</span>
            </div>
            <!-- Would Recommend Comments-->
            <div class="border-b-1">
              <p class="font-medium pl-2"> Comments</p>
              <el-form-item prop="wouldRecommendComments">
               <el-input placeholder="Comments" type="textarea" rows="3" class="mb-2 w-full" v-model="formFields.wouldRecommendComments" ></el-input>
                </el-form-item>
            </div>
            <!-- Extra Comments-->
            <div class="border-b-1">
              <p class="font-medium pl-2 mt-4"> Anything else you'd like to add?</p>
              <el-form-item prop="extraComments">
               <el-input placeholder="Comments" type="textarea" rows="10" class="mb-2 w-full" v-model="formFields.extraComments" ></el-input>
                </el-form-item>
            </div>
            <!-- Privacy -->
            <div>
              <p class="font-medium pl-2 mt-4"> Privacy</p>
              <p class="text-xs pl-2 mt-2">Muench Workshops will use the information you provide on this form to improve the services, instruction, and other factors for our future workshop. We will provide some of this information to travel providers and other parties that are assisting us on the workshop. We do this so that we can provide you with the best possible workshop experience. We also may use these comments in our marketing materials.</p>
              <br>
              <p class="text-xs pl-2 mt-2">You can also choose to subscribe to our newsletter, so that you will get updates on future workshops, special offers from us, and instructional materials that we send out. You can unsubscribe, or change your email preferences, by clicking the link in the footer of any newsletter email you receive from us, or by contacting us at info@muenchworkshops.com. </p>
              <br>
              <p class="text-xs pl-2 mt-2">Please review our <a class="text-blue-500 underline" target="_blank" href="https://muenchworkshops.com/privacy-and-cookies/">Privacy Policy</a>. By clicking “Send” below, you agree that we may process your information in accordance with these terms. We will never sell or rent your personal information.</p>
            </div>

            <!-- Submit -->
            <div class="space-y-2">
              <div class="flex w-full justify-center py-4">
                <el-button type="primary" @click="sendConfirmation('formFields')">Send Feedback</el-button>
              </div>
            </div>

            </div>
          </div>
        </el-form>
        
      </div>
    </div>
    <!--Submitted Messages-->
    <div v-else-if="this.submittedSuccesfully" class="flex justify-center ">
      <div class="border pl-4 pr-10 py-20 rounded shadow-md mb-4 w-7/8 md:w-4/5 text-center bg-white z-10 italic font-light whitespace-pre-wrap">
        {{ submitMessage }}
      </div>
    </div>
    <div v-else class="flex justify-center ">
      <div class="border pl-4 pr-10 py-20 rounded shadow-md mb-4 w-7/8 md:w-4/5 text-center bg-white z-10 italic font-light whitespace-pre-wrap">
        {{ showErrorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import sharedAPI from "@/api/SharedAPI.js";

export default {
  name: "Feedback",
  data() {
    return {
      formFields: {
        nameFirst: "",
        nameLast: "",
        email: "",
        workshopAttended: "",
        expectationsRating: null,
        expectationsComments: "",
        photoOppRating: null,
        photoOppComments: "",
        instructionRating: null,
        instructionComments: "",
        critiqueRating: null,
        critiqueComments: "",
        accomodationRating: null,
        accomodationComments: "",
        attendNewWorkshopRating: null,
        attendNewWorkshopComments: "",
        worthItRating: null,
        worthItComments: "",
        wouldRecommendRating: null,
        wouldRecommendComments: "",
        extraComments: "",
        otherWorkshop: false,
      },
      loading: false,
      loadingText: "Building your form...",
      submittedSuccesfully: false,
      submitMessage: "",
      showError: false,
      showErrorMessage: "",
      rules: {
        nameFirst: [{required: true, message: "First name is required", trigger: "blur",}],
        nameLast: [{ required: true, message: "Last name is required", trigger: "blur" },],
        email: [{ required: true, message: "Email is required", trigger: "blur" },],
        workshopAttended: [{ required: true, message: "Please enter workshop attended", trigger: "change" },],
        expectationsRating: [{ required: true, message: "Please enter workshop attended", trigger: "blur" },],
        },
      isInvalid: {
        expectationsRating: false,
        photoOppRating: false,
        instructionRating: false,
        critiqueRating: false,
        accomodationRating: false,
        attendNewWorkshopRating: false,
        worthItRating: false,
        wouldRecommendRating: false,
      },
      token: "",
      tokenTime: "",
      workshops: [],
    }
  },
  mounted() {
    this.loadData()
    document.title = 'We love feedback. It drives us to do better'
  },
  watch: {
    "formFields.otherWorkshop"() {
      this.formFields.workshopAttended = ""
    },
    "formFields.expectationsRating"() {
      if (this.formFields.expectationsRating > 0) {
        this.isInvalid.expectationsRating = false
      }
    },
    "formFields.photoOppRating"() {
      if (this.formFields.photoOppRating > 0) {
        this.isInvalid.photoOppRating = false
      }
    },
    "formFields.instructionRating"() {
      if (this.formFields.instructionRating > 0) {
        this.isInvalid.instructionRating = false
      }
    },
    "formFields.critiqueRating"() {
      if (this.formFields.critiqueRating > 0) {
        this.isInvalid.critiqueRating = false
      }
    },
    "formFields.accomodationRating"() {
      if (this.formFields.accomodationRating > 0) {
        this.isInvalid.accomodationRating = false
      }
    },
    "formFields.attendNewWorkshopRating"() {
      if (this.formFields.attendNewWorkshopRating > 0) {
        this.isInvalid.attendNewWorkshopRating = false
      }
    },
    "formFields.worthItRating"() {
      if (this.formFields.worthItRating > 0) {
        this.isInvalid.worthItRating = false
      }
    },
    "formFields.wouldRecommendRating"() {
      if (this.formFields.wouldRecommendRating > 0) {
        this.isInvalid.wouldRecommendRating = false
      }
    },
    token: {
      handler: "getWorkshops",
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        await this.getWorkshops();
      } 
      catch (e) {
        console.log(e);
        let params = {
          error: e
        }
        sharedAPI.runScript('WebLog-SendErrorEmail', JSON.stringify(params))
      } 
      finally {
        this.loading = false;
      }
    },

    async sendConfirmation(formFields) {
      var DOM = this;
      DOM.loadingText = "Sending..."
      DOM.loading = true
      setTimeout(() => {
        DOM.markRatingsInvalid()      
        this.$refs[formFields].validate((valid) => {
        if (valid && !DOM.hasInvalidRatings()) {
          DOM.send()
        } else {
          DOM.loading = false
          //this.$message.error("Please fill in all required fields.");
        }
        })
      }, 2000);
    },
    async send(){
      var DOM = this
      var data = DOM.formFields
      data.token = DOM.token
      var param = JSON.stringify(data)
      try{
        var response = await sharedAPI.submitFeedback(param)
        var data = response.data
        var result = JSON.parse(data.response.scriptResult)
        if(result.header){
          DOM.submitMessage = result.header
          DOM.submittedSuccesfully = true
          setTimeout(() => {
            window.location.replace('https://muenchworkshops.com')
          }, 5000);
        }
        else if(result.error){
          throw result.result
        }
        else{
          throw data.messages[0].message
        }
      }
      catch(e){
        DOM.showError = true
        DOM.showErrorMessage = e
        console.log(e)
        let params = {
          error: e,
          workshopDetails: this.formFields
        }
        sharedAPI.runScript('WebLog-SendErrorEmail', JSON.stringify(params))
        }
      finally{
        DOM.loading = false
      }
    },
    async getWorkshops() {
      var DOM = this;
      var date = new Date()
      date.setMonth(date.getMonth() - 6)
      var startDate = new Intl.DateTimeFormat("en-US").format(new Date(date))
      var endDate = new Intl.DateTimeFormat("en-US").format(new Date())
      var data = JSON.stringify({
        query: [
          {
            date: startDate + "..." + endDate,
          },
        ],
        token: this.token,
      });
      try{
        var response = await sharedAPI.getPastWorkshop(data);
          var data = response.data.response.data
          DOM.workshops = []
          data.forEach(item => {
            DOM.workshops.push({label: item.fieldData.WorkshopTitle, value: item.fieldData.__WorkshopsID_pk, date: item.fieldData.DateStart})
          })
          DOM.workshops.sort(function(a, b) {return new Date(b.date) - new Date(a.date)})
          DOM.loadingText = ""
      }
      catch(e){
        console.log(e);
      }
      finally{
        DOM.loading = false;
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      } 
    },

    markRatingsInvalid() {
      this.formFields.expectationsRating == 0 ? this.isInvalid.expectationsRating = true : this.isInvalid.expectationsRating = false
      this.formFields.photoOppRating == 0 ? this.isInvalid.photoOppRating = true : this.isInvalid.photoOppRating = false
      this.formFields.instructionRating == 0 ? this.isInvalid.instructionRating = true : this.isInvalid.instructionRating = false
      this.formFields.critiqueRating == 0 ? this.isInvalid.critiqueRating = true : this.isInvalid.critiqueRating = false
      this.formFields.accomodationRating == 0 ? this.isInvalid.accomodationRating = true : this.isInvalid.accomodationRating = false
      this.formFields.attendNewWorkshopRating == 0 ? this.isInvalid.attendNewWorkshopRating = true : this.isInvalid.attendNewWorkshopRating = false
      this.formFields.worthItRating == 0 ? this.isInvalid.worthItRating = true : this.isInvalid.worthItRating = false
      this.formFields.wouldRecommendRating == 0 ? this.isInvalid.wouldRecommendRating = true : this.isInvalid.wouldRecommendRating = false
    },

    hasInvalidRatings() {
      return this.isInvalid.expectationsRating || this.isInvalid.photoOppRating || this.isInvalid.instructionRating || this.isInvalid.critiqueRating || this.isInvalid.critiqueRating || 
      this.isInvalid.accomodationRating || this.isInvalid.attendNewWorkshopRating || this.isInvalid.worthItRating || this.isInvalid.wouldRecommendRating 
    },

    queryWorkshops(queryString, cb) {
      var workshops = this.workshops;
      var results = queryString ? workshops.filter(this.createFilter(queryString)) : workshops;
      // call callback function to return suggestions
      cb(results);
    },

    createFilter(queryString) {
      return (workshop) => {
        return (workshop.label.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
  }
};
</script>
<style scoped>
textarea {
  padding-top: 10px;
}
.invalid {
  border: #f56c6c 1px solid;
  border-radius: 4px;
  color: #f56c6c ;
}
.invalidText {
  transition:color .2s ease-in;
  color: #f56c6c ;
}
.validText {
  transition:color .2s ease-in;
  color: #FFFFFF ;
}
</style>